import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import {
  Calendar01,
  Gift,
  Home,
  Menu01,
  UsersProfiles01,
  Rocket,
  Package02,
  Ticket01,
} from 'lib/lucky-x-icons-2';
import Lottie from 'lottie-react';
import fireIcon from 'assets/icons/fireButtonIcon.json';

interface INavigationProps {}

const mbConfig = [
  {
    title: 'LuckyGo Plaza',
    icon: <Home size={24} />,
    path: '/raffle',
    blank: false,
  },
  {
    title: 'My raffles',
    icon: <Gift size={24} />,
    path: '/myraffles',
    blank: false,
  },
  {
    title: 'Calendar',
    icon: <Calendar01 size={24} />,
    path: '/calendar',
    blank: false,
  },
  {
    title: 'Project list',
    icon: <Menu01 size={24} />,
    path: '/project',
    blank: false,
  },
];

const launchConfig = [
  {
    title: 'Launchpad',
    icon: <Rocket size={24} />,
    path: '/launchpad',
    blank: false,
    tag: (
      <div className="automateBg absolute -right-2 -top-1 h-2 w-2 rounded-full group-hover:hidden xl_l:hidden"></div>
    ),
    tag2: (
      <div className="freeBg  hidden h-5 items-center justify-center rounded-full px-2 text-xs font-normal text-[#141416] group-hover:flex xl_l:flex">
        <Lottie animationData={fireIcon} style={{ width: 16 }} />
        NEW
      </div>
    ),
  },
  {
    title: 'My Inscriptions',
    icon: <Package02 size={24} />,
    path: '/myinscriptions',
    blank: false,
  },
  {
    title: 'LuckyMint',
    icon: <Ticket01 size={24} />,
    path: '/luckymint',
    blank: false,
    tag: (
      <div className="automateBg absolute -right-2 -top-1 h-2 w-2 rounded-full group-hover:hidden xl_l:hidden"></div>
    ),
    tag2: (
      <div className="freeBg  hidden h-5 items-center justify-center rounded-full px-2 text-xs font-normal text-[#141416] group-hover:flex xl_l:flex">
        BETA
      </div>
    ),
  },
];

const pcConfig = [
  {
    title: 'My Community',
    icon: <UsersProfiles01 size={24} />,
    path: '/builder',
    blank: true,
  },
];

const Navigation: React.FC<INavigationProps> = (props) => {
  const router = useRouter();
  const goPage = useCallback(
    (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      const blank = e.currentTarget.dataset.blank;
      const path = e.currentTarget.dataset.path;
      if (blank === 'true') {
        open(path);
      } else {
        open(path, '_self');
      }
    },
    []
  );
  return (
    <div className="">
      <ul className="mt-9.5 flex w-full cursor-pointer flex-col">
        {mbConfig.map((item) => (
          <li
            key={item.title}
            className={classNames(
              'relative flex h-12 shrink-0 items-center  gap-3 px-7 text-neutral-300 hover:text-neutral-100',
              {
                '!text-[#72F129]': item.path === router.pathname,
              }
            )}
            data-path={item.path}
            data-blank={item.blank}
            onClick={goPage}
          >
            <div className="-ml-[3px] shrink-0 duration-1000">{item.icon}</div>
            <p className="hidden text-sm font-medium transition duration-1000 group-hover:block xl_l:block">
              {item.title}
            </p>
            {item.path === router.pathname && (
              <i className="absolute left-0 block h-3 w-[4px] rounded-r-md bg-[#72F129]"></i>
            )}
          </li>
        ))}
      </ul>
      <div className="mx-5 my-3 h-px bg-divider-soft-dark"></div>
      <ul className="flex w-full cursor-pointer flex-col">
        {launchConfig.map((item) => (
          <li
            key={item.title}
            className={classNames(
              'relative flex h-12 shrink-0 items-center  gap-3 px-7 text-neutral-300 hover:text-neutral-100',
              {
                '!text-[#72F129]': item.path === router.pathname,
              }
            )}
            data-path={item.path}
            data-blank={item.blank}
            onClick={goPage}
          >
            <div className="relative -ml-[3px] shrink-0 duration-1000">
              {item.icon}
              {!!item?.tag && <>{item?.tag}</>}
            </div>
            <p className="hidden text-sm font-medium transition duration-1000 group-hover:block xl_l:block">
              {item.title}
            </p>
            {!!item.tag2 && <>{item?.tag2}</>}
            {item.path === router.pathname && (
              <i className="absolute left-0 block h-3 w-[4px] rounded-r-md bg-[#72F129]"></i>
            )}
          </li>
        ))}
      </ul>
      <div className="mx-5 my-3 h-px bg-divider-soft-dark"></div>
      <ul className="flex w-full cursor-pointer flex-col">
        {pcConfig.map((item) => (
          <li
            key={item.title}
            className="flex h-12 shrink-0 items-center gap-3  px-7 text-neutral-300 hover:text-neutral-100"
            data-path={item.path}
            data-blank={item.blank}
            onClick={goPage}
          >
            <div className="-ml-[3px] shrink-0 duration-1000 ">{item.icon}</div>
            <p className="hidden text-sm font-medium transition duration-1000 group-hover:block xl_l:block">
              {item.title}
            </p>
          </li>
        ))}
      </ul>
      <div className="mx-5 my-3 h-px bg-divider-soft-dark"></div>
    </div>
  );
};

export default Navigation;
