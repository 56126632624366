import React, { useCallback } from 'react';
import Navigation from './Navigation';
import siderLogo from 'assets/siderLogo.png';
import { useRouter } from 'next/router';
import {
  DiscordFillBrand,
  More02,
  TwitterFillBrand,
} from 'lib/lucky-x-icons-2';

interface ISiderProps {}

const Sider: React.FunctionComponent<ISiderProps> = (props) => {
  const router = useRouter();

  const goPage = useCallback(() => {
    if (router.pathname === '/raffle') {
      location.reload();
    } else {
      router.push('/raffle');
    }
  }, [router]);

  return (
    <div className="fixed left-0 top-0 z-[99] hidden h-full w-18.5 shrink-0 lg:block xl_l:w-[220px]">
      <div className="group absolute left-0 top-0  flex h-full w-full flex-col justify-between border-r border-r-divider-soft-dark bg-body-dark pt-9.5  hover:w-[220px] xl_l:w-[220px]">
        <div>
          <div className="m-auto flex h-10 justify-center gap-2">
            <div
              className="w-[42px] cursor-pointer overflow-hidden group-hover:w-auto xl_l:w-[142px]"
              onClick={goPage}
            >
              <img
                className="w-[142px] min-w-[142px]"
                src={siderLogo.src}
                alt=""
              />
            </div>
          </div>

          <Navigation />
        </div>
        <div className="flex justify-center pb-10 group-hover:hidden xl_l:hidden">
          <More02 size={24} color="#fff" />
        </div>
        <div className="hidden px-7 py-10 group-hover:block xl_l:block">
          <div className=" flex gap-4 text-[#D9D9D9] ">
            <a
              target="_blank"
              className="flex h-6 w-6 items-center justify-center"
              href="https://twitter.com/LuckyGo_io"
              rel="noreferrer"
            >
              <TwitterFillBrand
                className="text-neutral-100 hover:text-[#00A3FF]"
                size={24}
              />
            </a>
            <a
              target="_blank"
              className="flex h-6 w-6 items-center justify-center"
              href="https://discord.com/invite/Gykdc8PNwv"
              rel="noreferrer"
            >
              <DiscordFillBrand
                className="text-neutral-100 hover:text-[#5865F2]"
                size={24}
              />
            </a>
          </div>
          <p className="mt-2 text-sm text-neutral-400">© 2023 LuckyGo.io</p>
          <div className="mt-2 flex gap-2 text-xs text-white">
            <a
              target="_blank"
              href="https://luckybuy.gitbook.io/lucky+/"
              rel="noreferrer"
            >
              Terms of Use
            </a>
            <a
              target="_blank"
              href="https://luckybuy.gitbook.io/lucky+/privacy-policy"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sider;
