import React, { useEffect, useRef } from 'react';
import { useSize } from 'ahooks';
import classNames from 'classnames';

import NavBar from './NavBar';
import Sider from './Sider';
import { useAppDispatch } from 'store/hooks';
import {
  setOpenMenus,
  setOpenMore,
  setScreenWidth,
} from 'store/features/navSlice';

interface IProps {
  children: React.ReactNode;
  full?: boolean;
  btnList?: React.ReactNode;
  onClick?: (e: any) => void;
  isHiddenUser?: boolean;
}

const PageLayout: React.FC<IProps> = ({
  children,
  full = false,
  btnList,
  onClick,
  isHiddenUser = false,
}) => {
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const size = useSize(ref);

  useEffect(() => {
    dispatch(setOpenMenus(false));
    if (Number(size?.width) >= 1280) {
      dispatch(setOpenMore(false));
    }
    dispatch(setScreenWidth(Number(size?.width)));
  }, [dispatch, size?.width]);

  return (
    <div ref={ref} onClick={onClick}>
      <Sider />
      <NavBar btnList={btnList} isHiddenUser={isHiddenUser}></NavBar>
      <div className="pl-0 lg:pl-18.5 xl_l:pl-[220px]">
        <div
          className={classNames(
            'p-6 pt-8 lg:p-8 xl:p-10 xl:pt-8 2xl:px-[60px] 2xl:py-10',
            {
              '!lg:pt-10 !2xl:pt-[60px]': isHiddenUser,
            }
          )}
        >
          {full ? (
            children
          ) : (
            <div className="2xl:m-auto 2xl:max-w-[1600px]">{children}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageLayout;
