import ethereumIcon from '@/assets/icons/blockchain/Ethereum-fill-brand.png';
import polygonIcon from '@/assets/icons/blockchain/Polygon-fill-brand.png';
import bnbIcon from '@/assets/icons/blockchain/BNB-fill-brand.png';
import arbitrumIcon from '@/assets/icons/blockchain/Arbitrum-fill-brand.png';
import optimismIcon from '@/assets/icons/blockchain/Optimism-fill-brand.png';
import zKSyncIcon from '@/assets/icons/blockchain/ZKSync-fill-brand.png';
import solanaIcon from '@/assets/icons/blockchain/Solana-fill-brand.png';
import btcIcon from '@/assets/icons/blockchain/BTC-fill-brand.png';
import seiIcon from '@/assets/icons/blockchain/Sei-fill-brand.png';
import injIcon from '@/assets/icons/blockchain/Injective-fill-brand.png';
import blastIcon from '@/assets/icons/blockchain/Blast-fill-brand.png';
import avalancheIcon from '@/assets/icons/blockchain/Avalanche-fill-brand.png';
import starknetIcon from '@/assets/icons/blockchain/Starknet-fill-brand.png';
import tonIcon from '@/assets/icons/blockchain/Ton-fill-brand.png';

export const blockChainList = [
  {
    chain: 'Ethereum',
    chainName: 'Ethereum',
  },
  {
    chain: 'Polygon',
    chainName: 'Polygon',
  },
  {
    chain: 'BNB',
    chainName: 'BNB Chain',
  },
  {
    chain: 'Arbitrum',
    chainName: 'Arbitrum',
  },
  {
    chain: 'Optimism',
    chainName: 'Optimism',
  },
  {
    chain: 'ZKSync',
    chainName: 'ZK Sync',
  },
  {
    chain: 'Solana',
    chainName: 'Solana',
  },
  {
    chain: 'BTC_Ordinal',
    chainName: 'BTC Ordinals',
  },
  {
    chain: 'Sei_Network',
    chainName: 'Sei Network',
  },
  {
    chain: 'Injective',
    chainName: 'Injective',
  },
  {
    chain: 'Blast',
    chainName: 'Blast',
  },
  {
    chain: 'Avalanche',
    chainName: 'Avalanche',
  },
  {
    chain: 'Starknet',
    chainName: 'Starknet',
  },
  {
    chain: 'Ton',
    chainName: 'Ton',
  },
];

export function blockChainIcon(type: string) {
  let icon = ethereumIcon.src;
  switch (type) {
    case 'Polygon':
      icon = polygonIcon.src;
      break;
    case 'BNB':
      icon = bnbIcon.src;
      break;
    case 'Arbitrum':
      icon = arbitrumIcon.src;
      break;
    case 'Optimism':
      icon = optimismIcon.src;
      break;
    case 'ZKSync':
      icon = zKSyncIcon.src;
      break;
    case 'Solana':
      icon = solanaIcon.src;
      break;
    case 'BTC_Ordinal' || 'BRC20' || 'BTC':
      icon = btcIcon.src;
      break;
    case 'Sei_Network':
      icon = seiIcon.src;
      break;
    case 'Injective':
      icon = injIcon.src;
      break;
    case 'Blast':
      icon = blastIcon.src;
      break;
    case 'Avalanche':
      icon = avalancheIcon.src;
      break;
    case 'Starknet':
      icon = starknetIcon.src;
      break;
    case 'Ton':
      icon = tonIcon.src;
      break;
  }
  return icon;
}

export function blockchainUnit(type: string | undefined) {
  let unit = 'ETH';
  switch (type) {
    case 'BNB':
      unit = 'BNB';
      break;
    case 'Polygon':
      unit = 'MATIC';
      break;
    case 'Solana':
      unit = 'SOL';
      break;
    case 'BTC_Ordinal' || 'BRC20' || 'BTC':
      unit = 'BTC';
      break;
    case 'Sei_Network':
      unit = 'SEI';
      break;
    case 'Injective':
      unit = 'INJ';
      break;
    case 'Avalanche':
      unit = 'AVAX';
      break;
    case 'Ton':
      unit = 'TON';
      break;
  }
  return unit;
}
